import axios from '../config/axios';
import { API_URL } from '../config';

// Interfaces
import type { PaginatedResponse } from '../interfaces/api';
import type { Buque, BuquestListQueryparams, NewBuqueFormData } from '../interfaces/buques';

const BASE_ENDPOINT = `${API_URL}/barcos`;

/** @description Hace un request a la api y devuelve el número de buques pendientes. */
const getPendientesCount = async () => {
  return await axios.get<number>(`${BASE_ENDPOINT}/pendientes`).then((response) => {
    return response.data;
  });
};

/**
 * @description Hace un request a la api y devuelve un listado páginado de buques.
 * @param params parámetros de la query.
 * @returns Un objeto de tipo `PaginatedResponse<Buque>`.
 */
const getBuques = async (params: BuquestListQueryparams = {}) => {
  return await axios.get<PaginatedResponse<Buque>>(BASE_ENDPOINT, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Hace un request a la api y devuelve un buque.
 * @param buqueId id del buque a recuperar.
 * @returns Un objeto de tipo `Buque`.
 * @throws si no se encuentra el buque.
 * @throws si el usuario no tiene permisos para ver el buque.
 */
const getBuque = async (buqueId: number) => {
  return await axios.get<{ data: Buque }>(`${BASE_ENDPOINT}/${buqueId}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Hace un request a la api y crea un nuevo buque.
 * @param data datos del buque a crear.
 * @returns Un objeto de tipo `Buque`.
 * @throws si el usuario no tiene permisos para crear el buque.
 * @throws si el buque con el `OMI` ingresado ya existe.
 */
const newBuque = async (data: NewBuqueFormData) => {
  return await axios.post<{ data: Buque }>(BASE_ENDPOINT, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Hace un request a la api y edita un buque.
 * @param buqueId id del buque a editar.
 * @param data datos del buque a editar.
 * @returns Un objeto de tipo `Buque`.
 * @throws si el usuario no tiene permisos para editar el buque.
 */

const editBuque = async (buqueId: number, data: NewBuqueFormData) => {
  return await axios.patch<Buque>(`${BASE_ENDPOINT}/${buqueId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina un buque.
 * @param buqueId id del buque a eliminar.
 */
const deleteBuque = async (buqueId: number) => {
  return await axios.delete(`${BASE_ENDPOINT}/${buqueId}`);
};

/**
 * @description Hace un request a la api y genera un archivo con formato .XLSX que contiene el listado de buques.
 * @returns Un archivo con formato `.xlsx`.
 */
const exportBuques = async () => {
  return await axios.get(`${API_URL}/exportar/buques`).then((response) => {
    return response.data;
  });
};

const BuquesService = {
  getBuque,
  newBuque,
  getBuques,
  editBuque,
  deleteBuque,
  exportBuques,
  getPendientesCount
};

export default BuquesService;
