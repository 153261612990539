import React, { lazy, Suspense } from 'react';
import { useAppSelector } from './store/store';
import { ProtectedRoute } from './components/routes';
import { PageLoading } from '@ant-design/pro-layout';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

// Constants
import { ROUTES } from './constants/routes-constants';
import { PERMISSIONS } from './constants/permissions';

// Styles
import './styles/main.scss';

// Interfaces
import type { IUser } from './interfaces/user';

// Components
import LoginPage from './pages/auth/login';
import Error500Page from './pages/status/Pagina500';
import NotFoundPage from './pages/status/Pagina404';
import NotAllowedPage from './pages/status/Pagina403';
import ResetPassword from './pages/auth/resetPassword';
import ForgetPassword from './pages/auth/forgetPassword';

const ProfilePage = lazy(() => import('./pages/profile'));
const ViajesNewPage = lazy(() => import('./pages/viajes/new'));
const ViajesEditPage = lazy(() => import('./pages/viajes/edit'));
const LoteListaPage = lazy(() => import('./pages/lotes/listado'));
const LoteDetallePage = lazy(() => import('./pages/lotes/detalle'));
const ViajesListaPage = lazy(() => import('./pages/viajes/listado'));
const BuquesListaPage = lazy(() => import('./pages/buques/listado'));
const ViajeDetallePage = lazy(() => import('./pages/viajes/detalle'));
const PuertosListaPage = lazy(() => import('./pages/puertos/listado'));
const PuertosDetallePage = lazy(() => import('./pages/puertos/detalle'));
const ImportacionLotesPage = lazy(() => import('./pages/importacion/lotes'));
const ContenidoConteinerizadoPage = lazy(
  () => import('./pages/contenidoConteinerizado/pages/detalle')
);

/** Rutas a las que se puede acceder sin necesidad de autenticarse. */
const publicRoutes = [
  { path: '*', element: <NotFoundPage /> },
  { path: '/500', element: <Error500Page /> },
  { path: '/403', element: <NotAllowedPage /> },
  { path: ROUTES.LOGIN_ROUTE, element: <LoginPage /> },
  { path: ROUTES.RESET_PASSWORD_ROUTE, element: <ResetPassword /> },
  { path: ROUTES.FORGOT_PASSWORD_ROUTE, element: <ForgetPassword /> },
  { path: ROUTES.HOMEPAGE_ROUTE, element: <Navigate to={ROUTES.LOTES_ROUTE} replace /> }
];

/** Rutas que requieren autenticación. */
const privateRoutes = [
  /* ---------------------------------------
            RUTAS DEL PERFIL
 ---------------------------------------- */

  { path: ROUTES.PROFILE_ROUTE, element: <ProfilePage />, isAllowed: (user: IUser) => !!user },

  /* ---------------------------------------
            RUTAS DE PUERTOS
 ---------------------------------------- */
  {
    path: ROUTES.PUERTOS_ROUTE,
    element: <PuertosListaPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.browse_puertos)
  },
  {
    path: ROUTES.PUERTO_DETALLE_ROUTE,
    element: <PuertosDetallePage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.read_puertos)
  },

  /* ---------------------------------------
            RUTAS DE BUQUES
 ---------------------------------------- */
  {
    path: ROUTES.BUQUES_ROUTE,
    element: <BuquesListaPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.browse_barcos)
  },

  /* ---------------------------------------
            RUTAS DE VIAJES
 ---------------------------------------- */
  {
    path: ROUTES.VIAJES_ROUTE,
    element: <ViajesListaPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.browse_viajes)
  },
  {
    path: ROUTES.VIAJES_DETALLE_ROUTE,
    element: <ViajeDetallePage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.read_lotes)
  },

  /* ---------------------------------------
            RUTAS DE LOTES
 ---------------------------------------- */

  {
    path: ROUTES.LOTES_ROUTE,
    element: <LoteListaPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.browse_lotes)
  },
  {
    path: ROUTES.LOTE_DETALLE_ROUTE,
    element: <LoteDetallePage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.read_lotes)
  },
  {
    path: ROUTES.VIAJES_NEW_ROUTE,
    element: <ViajesNewPage />,
    isAllowed: (user: IUser) =>
      [PERMISSIONS.add_lotes, PERMISSIONS.add_viajes].every((p) => user.permissions.includes(p))
  },
  {
    path: ROUTES.VIAJES_EDIT_ROUTE,
    element: <ViajesEditPage />,
    isAllowed: (user: IUser) =>
      [PERMISSIONS.edit_lotes, PERMISSIONS.edit_viajes].every((p) => user.permissions.includes(p))
  },
  {
    path: ROUTES.LOTE_VIAJES_DETALLE_ROUTE,
    element: <ViajeDetallePage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.read_lotes)
  },
  {
    path: ROUTES.CONTENIDO_CONTEINERIZADO_DETALLE_ROUTE,
    element: <ContenidoConteinerizadoPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.read_lotes)
  },

  /* ---------------------------------------
            RUTAS DE IMPORTACIÓN
 ---------------------------------------- */
  {
    path: ROUTES.IMPORTACION_LOTES_ROUTE,
    element: <ImportacionLotesPage />,
    isAllowed: (user: IUser) => user.permissions.includes(PERMISSIONS.import_lotes)
  }
];

const RootComponent: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <Router>
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute isAllowed={Boolean(user)}>
                <Suspense fallback={<PageLoading />}>{route.element}</Suspense>
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
    </Router>
  );
};

export default RootComponent;
