import axios from '../config/axios';
import { API_URL } from '../config';

import { uniqBy } from 'lodash';
import type { Producto } from '../interfaces/producto';

/**
 * @description Recupera desde la API el listado de productos
 * @returns {Promise<Producto[]>}
 */
const getProductos = async (): Promise<Producto[]> => {
  return await axios.get<{ data: Producto[] }>(`${API_URL}/productos`).then((response) => {
    return response.data.data.map((producto) => {
      return {
        ...producto,
        presentaciones: uniqBy(producto.presentaciones, 'id')
      };
    });
  });
};

const ProductosService = {
  getProductos
};

export default ProductosService;
