import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import PaisesService from '../../services/paises.service';
import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof PaisesService.getPaises>>[number];

export const fetchPaises = createAsyncThunk<Entity[]>(
  'paises/fetchPaisesStatus',
  (_params, api) => {
    const { paises } = api.getState() as RootState;
    if (paises.entities.length) return Promise.resolve(paises.entities);
    return PaisesService.getPaises();
  }
);

interface PaisesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as PaisesState;

const paisesSlice = createSlice({
  name: 'paises',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaises.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPaises.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPaises.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const paisesActions = { ...paisesSlice.actions, fetchPaises };

export default paisesSlice.reducer;
