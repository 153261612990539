import { createSlice } from '@reduxjs/toolkit';

interface IntentoImportacionState {
  loading: boolean;
  errores: Record<string, any> | null;
}

const initialState = {
  loading: true,
  errores: null
} as IntentoImportacionState;

const intentoImportacionSlice = createSlice({
  name: 'intentoImportacion',
  initialState,
  reducers: {
    setErrors: (state, actions) => {
      state.errores = actions.payload;
    }
  }
});

export const agenteMaritimoActions = { ...intentoImportacionSlice.actions };

export default intentoImportacionSlice.reducer;
