import React from 'react';

// Theme
import { ConfigProvider } from 'antd';
import { PRIMARY_COLOR } from './constants/colors';

// Components
import RootComponent from './RootComponent';

// Redux
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

// Locale
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import es from 'antd/locale/es_ES';

import 'numeral/locales/es';
import numeral from 'numeral';

dayjs.locale('es');
numeral.locale('es');

const App: React.FC = () => {
  return (
    <ConfigProvider
      locale={es}
      theme={{
        token: {
          fontFamily: 'DINR',
          colorPrimary: PRIMARY_COLOR
        }
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootComponent />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
