const PERMISSIONS_LIST = [
  'browse_admin',
  'browse_bread',
  'browse_database',
  'browse_media',
  'browse_compass',
  'browse_menus',
  'read_menus',
  'edit_menus',
  'add_menus',
  'delete_menus',
  'browse_roles',
  'read_roles',
  'edit_roles',
  'add_roles',
  'delete_roles',
  'browse_users',
  'read_users',
  'edit_users',
  'add_users',
  'delete_users',
  'browse_settings',
  'read_settings',
  'edit_settings',
  'add_settings',
  'delete_settings',
  'browse_puertos',
  'read_puertos',
  'edit_puertos',
  'add_puertos',
  'delete_puertos',
  'browse_transporte',
  'read_transporte',
  'edit_transporte',
  'add_transporte',
  'delete_transporte',
  'browse_navieras',
  'read_navieras',
  'edit_navieras',
  'add_navieras',
  'delete_navieras',
  'browse_agentes_maritimos',
  'read_agentes_maritimos',
  'edit_agentes_maritimos',
  'add_agentes_maritimos',
  'delete_agentes_maritimos',
  'browse_categorias_facturacion',
  'read_categorias_facturacion',
  'edit_categorias_facturacion',
  'add_categorias_facturacion',
  'delete_categorias_facturacion',
  'browse_gastos_incluidos',
  'read_gastos_incluidos',
  'edit_gastos_incluidos',
  'add_gastos_incluidos',
  'delete_gastos_incluidos',
  'browse_localidades',
  'read_localidades',
  'edit_localidades',
  'add_localidades',
  'delete_localidades',
  'browse_provincias',
  'read_provincias',
  'edit_provincias',
  'add_provincias',
  'delete_provincias',
  'browse_paises',
  'read_paises',
  'edit_paises',
  'add_paises',
  'delete_paises',
  'browse_modalidad_fletamiento',
  'read_modalidad_fletamiento',
  'edit_modalidad_fletamiento',
  'add_modalidad_fletamiento',
  'delete_modalidad_fletamiento',
  'browse_modos_explotacion',
  'read_modos_explotacion',
  'edit_modos_explotacion',
  'add_modos_explotacion',
  'delete_modos_explotacion',
  'browse_presentacion',
  'read_presentacion',
  'edit_presentacion',
  'add_presentacion',
  'delete_presentacion',
  'browse_productos',
  'read_productos',
  'edit_productos',
  'add_productos',
  'delete_productos',
  'browse_sitios_atraque',
  'read_sitios_atraque',
  'edit_sitios_atraque',
  'add_sitios_atraque',
  'delete_sitios_atraque',
  'browse_subcategoria_facturacion',
  'read_subcategoria_facturacion',
  'edit_subcategoria_facturacion',
  'add_subcategoria_facturacion',
  'delete_subcategoria_facturacion',
  'browse_terminales',
  'read_terminales',
  'edit_terminales',
  'add_terminales',
  'delete_terminales',
  'browse_tipo_buque',
  'read_tipo_buque',
  'edit_tipo_buque',
  'add_tipo_buque',
  'delete_tipo_buque',
  'browse_tipo_contenedor',
  'read_tipo_contenedor',
  'edit_tipo_contenedor',
  'add_tipo_contenedor',
  'delete_tipo_contenedor',
  'browse_tipo_navegacion',
  'read_tipo_navegacion',
  'edit_tipo_navegacion',
  'add_tipo_navegacion',
  'delete_tipo_navegacion',
  'browse_tipos_operaciones',
  'read_tipos_operaciones',
  'edit_tipos_operaciones',
  'add_tipos_operaciones',
  'delete_tipos_operaciones',
  'browse_lotes',
  'read_lotes',
  'edit_lotes',
  'add_lotes',
  'delete_lotes',
  'import_lotes',
  'validate_lotes',
  'audit_lotes',
  'reopen_lotes',
  'browse_barcos',
  'read_barcos',
  'edit_barcos',
  'add_barcos',
  'delete_barcos',
  'audit_barcos',
  'validate_barcos',
  'audit_puertos',
  'browse_viajes',
  'add_viajes',
  'edit_viajes',
  'delete_viajes',
  'audit_viajes',
  'observe_viajes',
  'browse_facturado_viaje',
  'add_facturado_viaje',
  'edit_facturado_viaje',
  'delete_facturado_viaje',
  'browse_facturacion',
  'add_facturacion',
  'edit_facturacion',
  'delete_facturacion',
  'browse_logistica',
  'add_logistica',
  'edit_logistica',
  'delete_logistica',
  'browse_operaciones_viaje',
  'add_operaciones_viaje',
  'edit_operaciones_viaje',
  'delete_operaciones_viaje',
  'browse_tipos_operaciones_viajes',
  'add_tipos_operaciones_viajes',
  'edit_tipos_operaciones_viajes',
  'delete_tipos_operaciones_viajes'
] as const;

type ToObj<K extends string> = {
  [P in K]: P;
};

export type PERMISSIONS_KEYS = (typeof PERMISSIONS_LIST)[number];

export type PERMISSIONS_ENUM = ToObj<PERMISSIONS_KEYS>;

export const PERMISSIONS = PERMISSIONS_LIST.reduce((acc, elem) => {
  return {
    ...acc,
    [elem]: elem
  };
}, {} as Record<PERMISSIONS_KEYS, PERMISSIONS_KEYS>);
