import axios from '../config/axios';
import { API_URL } from '../config';

// Interfaces
import type {
  Puerto,
  PuertoDetalle,
  PuertoTerminal,
  NewPuertoFormData,
  PuertostListQueryparams,
  NewPuertoTerminalFormData
} from '../interfaces/puertos';
import type { PaginatedResponse } from '../interfaces/api';
import type { SitioAtraque } from '../interfaces/sitioAtraque';
import type { Auditoria, AuditoriaQueryparams } from '../interfaces/auditoria';

const BASE_ENDPOINT = `${API_URL}/puertos`;

/* ---------------------------------------
                  Puertos
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado paginado de puertos vigentes
 * @param {PuertostListQueryparams} params
 * @returns {Promise<PaginatedResponse<Puerto>>}
 */
const getPuertos = async (params: PuertostListQueryparams = {}) => {
  return await axios.get<PaginatedResponse<Puerto>>(BASE_ENDPOINT, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API la cantidad de puertos pendientes de aprobación
 * @returns {Promise<number>}
 */
const getPendientesCount = async () => {
  return await axios.get<number>(`${BASE_ENDPOINT}/pendientes`).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API el listado de puertos que son del sistema, es decir los puertos sobre los que se pueden crear lotes
 * @returns {Promise<Puerto[]>}
 */
const getPuertosDelSistema = async () => {
  return await axios
    .get<PaginatedResponse<Puerto>>(BASE_ENDPOINT, {
      params: { del_sistema: true } as PuertostListQueryparams
    })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el detalle de un puerto
 * @param {number} puertoId
 * @returns {Promise<PuertoDetalle>}
 * @throws si no se encuentra el puerto.
 * @throws si el usuario no tiene permisos para ver el puerto.
 */
const getPuerto = async (puertoId: number) => {
  return await axios
    .get<{ data: PuertoDetalle }>(`${BASE_ENDPOINT}/${puertoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado paginado de auditorias de un puerto
 * @param {number} puertoId
 * @param {AuditoriaQueryparams} params
 * @returns {Promise<PaginatedResponse<Auditoria>>}
 * @throws si no se encuentra el puerto.
 * @throws si el usuario no tiene permisos para ver el puerto.
 * @throws si el usuario no tiene permisos para ver las auditorias del puerto.
 */
const getPuertoAuditorias = async (puertoId: number, params: AuditoriaQueryparams) => {
  return await axios
    .get<PaginatedResponse<Auditoria>>(`${BASE_ENDPOINT}/${puertoId}/auditorias/`, { params })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Crea un nuevo puerto, y devuelve el puerto creado. En caso de ser un usuario de puertos, el puerto queda pendiente de aprobación por alguien de la dirección.
 * @param {number} puertoId
 * @param {AuditoriaQueryparams} params
 * @returns {Promise<PaginatedResponse<Auditoria>>}
 */
const newPuerto = async (data: NewPuertoFormData) => {
  return await axios.post<Puerto>(BASE_ENDPOINT, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Edita un puerto, y devuelve el puerto editado.
 * @param {number} puertoId
 * @param {NewPuertoFormData} data
 * @returns {Promise<Puerto>}
 */
const editPuerto = async (puertoId: number, data: NewPuertoFormData) => {
  return await axios.patch<Puerto>(`${BASE_ENDPOINT}/${puertoId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Hace un request a la api y genera un archivo con formato .XLSX que contiene el listado de puertos.
 * @returns Un archivo con formato `.xlsx`.
 */
const exportPuertos = async () => {
  return await axios.get(`${API_URL}/exportar/puertos`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
                TERMINALES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de terminales de un puerto
 * @param {number} puertoId
 * @param {any} params
 * @returns {Promise<PuertoTerminal[]>}
 */
const getTerminales = async (puertoId: number, params: any = {}) => {
  return await axios
    .get<{ data: PuertoTerminal[] }>(`${BASE_ENDPOINT}/${puertoId}/terminales`, { params })
    .then((response) => {
      return response.data.data;
    });
};

const newTerminal = async (puertoId: number, data: NewPuertoTerminalFormData) => {
  return await axios
    .post<PuertoTerminal[]>(`${BASE_ENDPOINT}/${puertoId}/terminales`, data)
    .then((response) => {
      return response.data;
    });
};

const editTerminal = async (
  puertoId: number,
  terminalId: number,
  data: NewPuertoTerminalFormData
) => {
  return await axios
    .patch<PuertoTerminal>(`${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteTerminal = async (puertoId: number, terminalId: number) => {
  return await axios
    .delete<PuertoTerminal>(`${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
            SITIOS DE ATRAQUE
 ---------------------------------------- */

const getSitiosAtraque = async (puertoId: number, terminalId: number, params: any = {}) => {
  return await axios
    .get<SitioAtraque[]>(`${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}/sitios-atraque`, {
      params
    })
    .then((response) => {
      return response.data;
    });
};

const newSitioAtraque = async (
  puertoId: number,
  terminalId: number,
  data: NewPuertoTerminalFormData
) => {
  return await axios
    .post<SitioAtraque>(
      `${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}/sitios-atraque`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const editSitioAtraque = async (
  puertoId: number,
  terminalId: number,
  sitioId: number,
  data: NewPuertoTerminalFormData
) => {
  return await axios
    .patch<SitioAtraque>(
      `${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}/sitios-atraque/${sitioId}/`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const deleteSitioAtraque = async (puertoId: number, terminalId: number, sitioId: number) => {
  return await axios
    .delete<SitioAtraque>(
      `${BASE_ENDPOINT}/${puertoId}/terminales/${terminalId}/sitios-atraque/${sitioId}/`
    )
    .then((response) => {
      return response.data;
    });
};

const PuertosService = {
  newPuerto,
  getPuerto,
  editPuerto,
  getPuertos,
  newTerminal,
  editTerminal,
  exportPuertos,
  getTerminales,
  deleteTerminal,
  newSitioAtraque,
  getSitiosAtraque,
  editSitioAtraque,
  getPendientesCount,
  deleteSitioAtraque,
  getPuertoAuditorias,
  getPuertosDelSistema
};

export default PuertosService;
