import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Service
import ModoExplotacionService from '../../services/modoExplotacion.service';

// Interfaces
import type { RootState } from '../store';
type Entity = Awaited<ReturnType<typeof ModoExplotacionService.getModosDeExplotacion>>[number];

export const fetchModosDeExplotacion = createAsyncThunk<Entity[]>(
  'modosExplotacion/fetchModosDeExplotacionStatus',
  (_params, api) => {
    const { modosDeExplotacion } = api.getState() as RootState;
    if (modosDeExplotacion.entities.length) return Promise.resolve(modosDeExplotacion.entities);
    return ModoExplotacionService.getModosDeExplotacion();
  }
);

interface ModoExplotacionState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as ModoExplotacionState;

const modosExplotacionSlice = createSlice({
  name: 'modosExplotacion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModosDeExplotacion.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchModosDeExplotacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchModosDeExplotacion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const ModosExplotacionActions = {
  ...modosExplotacionSlice.actions,
  fetchModosDeExplotacion
};

export default modosExplotacionSlice.reducer;
