import axios from '../config/axios';
import { API_URL } from '../config';

import type { TipoBuque } from '../interfaces/tipoBuque';

/**
 * @description Recupera desde la API el listado de tipos de buques
 * @returns {Promise<TipoBuque[]>}
 */
const getTiposBuques = async () => {
  return await axios.get<{ data: TipoBuque[] }>(`${API_URL}/tipos-buque`).then((response) => {
    return response.data.data;
  });
};

const TiposBuqueService = {
  getTiposBuques
};

export default TiposBuqueService;
