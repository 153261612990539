import { Button, message, notification } from 'antd';
import { memo, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, ReadOutlined } from '@ant-design/icons';
import { LoginFormPage, ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Constants
import { PRIMARY_COLOR } from '../../../constants/colors';

// Services
import AuthService from '../../../services/auth.service';

// Interfaces
import type { FC } from 'react';
import type { ResetPasswordParams } from './interfaces';

import './styles.scss';

export const ForgetPassword: FC = memo(() => {
  const ref = useRef<ProFormInstance<ResetPasswordParams>>();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: ResetPasswordParams) => {
      return await AuthService.resetPassword(data.code, data.password)
        .then(() => {
          message.success('Contraseña actualizada correctamente.');
          navigate('/');
          return true;
        })
        .catch((e: any) => {
          if (e && e.response && e.response.data && e.response.data.message) {
            notification.error({
              closeIcon: false,
              placement: 'bottomLeft',
              description: e.response.data.message,
              message: 'Error'
            });
          } else {
            notification.error({
              closeIcon: false,
              placement: 'bottomLeft',
              description:
                'Ocurrió un error inesperado al actualizar la contraseña. Si el problema persiste, ponganse en contacto con el area de soporte.',
              message: 'Error'
            });
          }

          return false;
        });
    },
    [navigate]
  );

  return (
    <div className="login-page-container">
      <LoginFormPage<ResetPasswordParams>
        formRef={ref}
        autoComplete="off"
        onFinish={onSubmit}
        title="Olvidé mi contraseña"
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div style={{ marginTop: 15 }}>
                  <Button {...props.submitButtonProps} type="primary" onClick={props.submit}>
                    Actualizar contraseña
                  </Button>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Link to="/ingresar">
                    <Button {...props.submitButtonProps} loading={false}>
                      Volver a inicio
                    </Button>
                  </Link>
                </div>

                <img
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.svg`}
                />
              </div>
            );
          }
        }}
        subTitle={
          <div className="login-page-subtitle">
            Plataforma informática operatoria portuaria bonaerense
          </div>
        }
        backgroundImageUrl={`${process.env.PUBLIC_URL}/login_background1.jpg`}
        activityConfig={{
          style: {
            color: '#fff',
            maxWidth: 500,
            borderRadius: 8,
            backgroundColor: PRIMARY_COLOR,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)'
          },
          title: 'Te damos la bienvenida',
          subTitle:
            'En caso de no contar con credenciales de acceso, será necesario solicitarlas al administrador.'
        }}
      >
        <div>
          <div>
            <ProFormText
              name="code"
              fieldProps={{
                size: 'large',
                autoComplete: 'off',
                prefix: <ReadOutlined className={'prefixIcon'} />
              }}
              placeholder={'Código'}
              tooltip="Debes completar este campo con el código que enviamos a tu correo."
              rules={[
                {
                  required: true,
                  message: 'Debe completar este campo'
                }
              ]}
            />

            <ProFormText.Password
              name="password"
              label="Nueva contraseña"
              fieldProps={{
                size: 'large',
                autoComplete: 'off',
                prefix: <LockOutlined className={'prefixIcon'} />
              }}
              placeholder="Ingrese la nueva contraseña"
              rules={[
                { min: 8, message: 'Debe tener al menos 8 caracteres' },
                { required: true, message: ' ' }
              ]}
            />

            <ProFormText.Password
              name="new_password_two"
              label="Reingrese la nueva contraseña"
              placeholder="Reingrese su contraseña actual"
              fieldProps={{
                size: 'large',
                autoComplete: 'off',
                prefix: <LockOutlined className={'prefixIcon'} />
              }}
              rules={[
                { required: true, message: ' ' },
                { min: 8, message: 'Debe tener al menos 8 caracteres' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                  }
                })
              ]}
            />
          </div>
        </div>
      </LoginFormPage>
    </div>
  );
});

ForgetPassword.displayName = 'ForgetPassword';

export default ForgetPassword;
