import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import NavierasService from '../../services/naviera.service';
import { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof NavierasService.getNavieras>>[number];

export const fetchNavieras = createAsyncThunk<Entity[]>(
  'navieras/fetchNavierasStatus',
  (_params, api) => {
    const { navieras } = api.getState() as RootState;
    if (navieras.entities.length) return Promise.resolve(navieras.entities);
    return NavierasService.getNavieras();
  }
);

interface NavieraState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as NavieraState;

const navieraSlice = createSlice({
  name: 'navieras',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNavieras.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchNavieras.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchNavieras.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const navierasActions = { ...navieraSlice.actions, fetchNavieras };

export default navieraSlice.reducer;
