import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor, faBox, faFile } from '@fortawesome/free-solid-svg-icons';
import { faMap, faShip, faBuilding, faBook } from '@fortawesome/free-solid-svg-icons';

export const BuqueIcon = (props: any) => <FontAwesomeIcon icon={faShip} {...props} />;

export const PuertoIcon = (props: any) => <FontAwesomeIcon icon={faAnchor} {...props} />;

export const ViajeIcon = (props: any) => <FontAwesomeIcon icon={faMap} {...props} />;

export const ImportarIcon = (props: any) => <FontAwesomeIcon icon={faFile} {...props} />;

export const LoteIcon = (props: any) => <FontAwesomeIcon icon={faBox} {...props} />;

export const AgenteMaritimoIcon = (props: any) => <FontAwesomeIcon icon={faBuilding} {...props} />;

export const NavieraIcon = (props: any) => <FontAwesomeIcon icon={faBook} {...props} />;
