import { UserOutlined } from '@ant-design/icons';
import { memo, useCallback, useRef } from 'react';
import { Button, Modal, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { LoginFormPage, ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Services
import AuthService from '../../../services/auth.service';

// Constants
import { PRIMARY_COLOR } from '../../../constants/colors';
import { ROUTES } from '../../../constants/routes-constants';

// Styles
import './styles.scss';

// Interfaces
import type { FC } from 'react';
import type { ForgotPasswordParams } from './interfaces';

export const ForgetPassword: FC = memo(() => {
  const navigate = useNavigate();
  const ref = useRef<ProFormInstance<ForgotPasswordParams>>();

  const onSubmit = useCallback(
    async (data: ForgotPasswordParams) => {
      return await AuthService.forgotPassword(data.email)
        .then(() => {
          Modal.success({
            width: 600,
            centered: true,
            title: 'Email enviado',
            okButtonProps: { style: { backgroundColor: PRIMARY_COLOR } },
            content:
              'Asegurate de haber recibido un email en tu casilla de correo. Será necesario que copies el código que te enviamos y que presiones continuar'
          });
          navigate(ROUTES.RESET_PASSWORD_ROUTE);
          return true;
        })
        .catch(() => {
          notification.error({
            closeIcon: false,
            placement: 'bottomLeft',
            description: 'Parece que el email ingresado no está registrado en el sistema.',
            message: 'Error'
          });
          return false;
        });
    },
    [navigate]
  );

  return (
    <div className="login-page-container">
      <LoginFormPage<ForgotPasswordParams>
        formRef={ref}
        autoComplete="off"
        onFinish={onSubmit}
        title="Olvidé mi contraseña"
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div style={{ marginTop: 15 }}>
                  <Button {...props.submitButtonProps} type="primary" onClick={props.submit}>
                    Solicitar cambio de contraseña
                  </Button>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Link to="/ingresar">
                    <Button {...props.submitButtonProps} loading={false}>
                      Volver a inicio
                    </Button>
                  </Link>
                </div>

                <img
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.svg`}
                />
              </div>
            );
          }
        }}
        subTitle={
          <div className="login-page-subtitle">
            Plataforma informática operatoria portuaria bonaerense
          </div>
        }
        backgroundImageUrl={`${process.env.PUBLIC_URL}/login_background1.jpg`}
        activityConfig={{
          style: {
            color: '#fff',
            maxWidth: 500,
            borderRadius: 8,
            backgroundColor: PRIMARY_COLOR,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)'
          },
          title: 'Te damos la bienvenida',
          subTitle:
            'En caso de no contar con credenciales de acceso, será necesario solicitarlas al administrador.'
        }}
      >
        <div>
          <div>
            <ProFormText
              name="email"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className={'prefixIcon'} />
              }}
              placeholder={'Email'}
              rules={[
                {
                  required: true,
                  message: 'Debe completar este campo'
                },
                {
                  type: 'email',
                  message: 'Ingrese un email válido'
                }
              ]}
            />
          </div>
        </div>
      </LoginFormPage>
    </div>
  );
});

ForgetPassword.displayName = 'ForgetPassword';

export default ForgetPassword;
