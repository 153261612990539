import axios from 'axios';
import { API_URL } from '../config';
import { store } from '../store/store';

import type { IToken } from '../interfaces/user';
import { authActions } from '../store/slices/auth';

import MeService from './me.service';

const login = async (email: string, password: string) => {
  const response = await axios.post<IToken>(`${API_URL}/login`, {
    email,
    password
  });
  localStorage.setItem('accessToken', response.data.access_token);
  const user = await MeService.getCurrentUser();
  store.dispatch(authActions.setUser({ user }));
  return user;
};

const logout = async () => {
  localStorage.removeItem('user');
  return await axios.post<void>(`${API_URL}/logout`).then((response) => {
    return response.data;
  });
};

/** Este endpoint genera un código de recuperación de contraseña que se envia al email del usuario. */
const forgotPassword = async (email: string) => {
  return await axios.post<void>(`${API_URL}/forgot-password`, { email }).then((response) => {
    return response.data;
  });
};

/** Este endpoint se utiliza para setear una nueva contraseña al usuario utilizando el código enviado por email. */
const resetPassword = async (token: string, password: string) => {
  return await axios
    .post<any>(`${API_URL}/reset-password`, { token, password })
    .then((response) => {
      return response.data;
    });
};

const AuthService = {
  login,
  logout,
  resetPassword,
  forgotPassword
};

export default AuthService;
