import axios from '../config/axios';
import { API_URL } from '../config';

import type { Localidad, Pais, Provincia } from '../interfaces/pais';

/**
 * @description Recupera desde la API el listado de paises vigentes
 * @returns {Promise<Pais[]>}
 */
const getPaises = async () => {
  return await axios.get<{ data: Pais[] }>(`${API_URL}/paises`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Recupera desde la API el listado de provincias vigentes
 * @returns {Promise<Provincia[]>}
 * @param {number} paisId ID del pais al que pertenecen las provincias solicitadas
 */
const getProvincias = async (paisId: number) => {
  return await axios.get<Provincia[]>(`${API_URL}/paises/${paisId}/provincias`).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API el listado de localidades vigentes
 * @returns {Promise<Localidad[]>}
 * @param {number} paisId ID del pais al que pertenecen las localidades solicitadas
 * @param {number} provinciaId ID de la provincia a la que pertenecen las localidades solicitadas
 */
const getLocalidades = async (paisId: number, provinciaId: number) => {
  return await axios
    .get<Localidad[]>(`${API_URL}/paises/${paisId}/provincias/${provinciaId}/localidades`)
    .then((response) => {
      return response.data;
    });
};

const PaisesService = {
  getPaises,
  getProvincias,
  getLocalidades
};

export default PaisesService;
