// Icons
import { NavieraIcon, PuertoIcon, ViajeIcon } from './components/icons';
import { AgenteMaritimoIcon, BuqueIcon, ImportarIcon, LoteIcon } from './components/icons';

// Constants
import { PARAMETROS_URL } from '../../config';
import { PERMISSIONS } from '../../constants/permissions';

// Interfaces
import type { IUser } from '../../interfaces/user';

const iconStyles = { marginLeft: 0 };

export default (isMobile: boolean, user: IUser) => {
  const { permissions } = user;

  const routes = [];

  if (
    permissions.some((p) =>
      [
        PERMISSIONS.add_lotes,
        PERMISSIONS.edit_lotes,
        PERMISSIONS.browse_lotes,
        PERMISSIONS.validate_lotes
      ].includes(p)
    )
  ) {
    routes.push({
      path: '/lotes',
      name: 'Carga manual',
      icon: <LoteIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />
    });
  }

  if (permissions.some((p) => [PERMISSIONS.import_lotes].includes(p))) {
    routes.push({
      name: 'Subir planilla monitor',
      path: '/importacion',
      icon: <ImportarIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />
    });
  }

  if (
    permissions.some((p) =>
      [
        PERMISSIONS.add_lotes,
        PERMISSIONS.edit_lotes,
        PERMISSIONS.browse_lotes,
        PERMISSIONS.validate_lotes
      ].includes(p)
    )
  ) {
    routes.push({
      path: '/estadias',
      name: 'Estadías cargadas',
      icon: <ViajeIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (
    permissions.some((p) =>
      [
        PERMISSIONS.add_barcos,
        PERMISSIONS.edit_barcos,
        PERMISSIONS.read_barcos,
        PERMISSIONS.browse_barcos
      ].includes(p)
    )
  ) {
    routes.push({
      path: '/buques',
      name: 'Buques cargados',
      icon: <BuqueIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (permissions.some((p) => [PERMISSIONS.add_puertos, PERMISSIONS.edit_puertos].includes(p))) {
    routes.push({
      path: '/puertos',
      name: 'Puertos',
      icon: <PuertoIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (
    permissions.some((p) => [PERMISSIONS.add_agentes_maritimos].includes(p)) &&
    user.roles.find((r) => r.name === 'puertos')
  ) {
    routes.push({
      path: '/',
      name: 'Agentes marítimos',
      href: `${PARAMETROS_URL}/agentes-maritimos`,
      icon: <AgenteMaritimoIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (
    permissions.some((p) => [PERMISSIONS.add_navieras].includes(p)) &&
    user.roles.find((r) => r.name === 'puertos')
  ) {
    routes.push({
      path: '/',
      name: 'Navieras',
      href: `${PARAMETROS_URL}/navieras`,
      icon: <NavieraIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  return {
    routes
  };
};
