import axios from '../config/axios';
import { API_URL } from '../config';

import type {
  CategoriaFacturacion,
  SubcategoriaFacturacion
} from '../interfaces/categoriasFacturacion';

/**
 * @description Recupera desde la API el listado de categorias de facturación vigentes
 */
const getCategoriasFacturacion = async () => {
  return await axios
    .get<{ data: CategoriaFacturacion[] }>(`${API_URL}/categorias-facturacion`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de facturación vigentes
 */
const getSubcategoriasFacturacion = async () => {
  return await axios
    .get<{ data: SubcategoriaFacturacion[] }>(`${API_URL}/subcategorias-facturacion`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasFacturacionService = {
  getCategoriasFacturacion,
  getSubcategoriasFacturacion
};

export default CategoriasFacturacionService;
