import axios from '../config/axios';
import { API_URL } from '../config';

// Interfaces

import type {
  Viaje,
  ViajeDetalle,
  NewViajeFormData,
  EditViajeFormData,
  ViajestListQueryparams
} from '../interfaces/viajes';
import type {
  TipoOperacionViaje,
  NewTipoOperacionViaje,
  ContenidoConteinerizadoViaje,
  ContenidoNoConteinerizadoViaje,
  NewContenidoConteinerizadoViaje,
  NewContenidoNoConteinerizadoViaje,
  EditContenidoNoConteinerizadoViaje,
  ProductoContenidoConteinerizadoViaje,
  NewProductoContenidoConteinerizadoViaje,
  EditProductoContenidoConteinerizadoViaje
} from '../interfaces/tiposOperaciones';
import type { Auditoria, AuditoriaQueryparams } from '../interfaces/auditoria';
import type { PaginatedQueryParams, PaginatedResponse } from '../interfaces/api';
import type { FacturadoViaje, FacturadoViajeQueryparams } from '../interfaces/facturadoViaje';
import type { EstadiaTerminal, EstadiaTerminalFormData } from '../interfaces/estadiaTerminal';

const BASE_ENDPOINT = `${API_URL}/viajes`;

/* ---------------------------------------
                  Viajes
 ---------------------------------------- */

const getViajes = (params: ViajestListQueryparams = {}) => {
  return axios.get<PaginatedResponse<Viaje>>(BASE_ENDPOINT, { params }).then((response) => {
    return response.data;
  });
};

const getViaje = (viajeId: number) => {
  return axios.get<{ data: ViajeDetalle }>(`${BASE_ENDPOINT}/${viajeId}`).then((response) => {
    return response.data.data;
  });
};

/* ---------------------------------------
                Auditorías
 ---------------------------------------- */

const getViajeAuditorias = (viajeId: number, params: AuditoriaQueryparams) => {
  return axios
    .get<PaginatedResponse<Auditoria>>(`${BASE_ENDPOINT}/${viajeId}/auditorias/`, { params })
    .then((response) => {
      return response.data.data;
    });
};

/* ---------------------------------------
          Facturación de viajes
 ---------------------------------------- */

const getViajeFacturado = async (viajeId: number, params: FacturadoViajeQueryparams) => {
  return await axios
    .get<PaginatedResponse<FacturadoViaje>>(`${BASE_ENDPOINT}/${viajeId}/facturado/`, { params })
    .then((response) => {
      return response.data.data;
    });
};

const newViajeFacturado = async (viajeId: number, data: NewViajeFormData) => {
  return await axios
    .post<FacturadoViaje>(`${BASE_ENDPOINT}/${viajeId}/facturado/`, data)
    .then((response) => {
      return response.data;
    });
};

const editViajeFacturado = async (
  viajeId: number,
  facturadoId: number,
  data: EditViajeFormData
) => {
  return await axios
    .patch<FacturadoViaje>(`${BASE_ENDPOINT}/${viajeId}/facturado/${facturadoId}/`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteViajeFacturado = async (viajeId: number, facturadoId: number) => {
  return await axios
    .delete<FacturadoViaje>(`${BASE_ENDPOINT}/${viajeId}/facturado/${facturadoId}/`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
            Estadías en terminales
 ---------------------------------------- */

const getViajeEstadiasTerminales = async (viajeId: number) => {
  return await axios
    .get<{ data: EstadiaTerminal[] }>(`${BASE_ENDPOINT}/${viajeId}/estadias/`)
    .then((response) => {
      return response.data.data;
    });
};

const getViajeEstadiaTerminal = async (viajeId: number, estadiaId: number) => {
  return await axios
    .get<{ data: EstadiaTerminal }>(`${BASE_ENDPOINT}/${viajeId}/estadias/${estadiaId}`)
    .then((response) => {
      return response.data.data;
    });
};

const newViajeEstadiaTerminal = async (viajeId: number, data: EstadiaTerminalFormData) => {
  return await axios
    .post<EstadiaTerminal>(`${BASE_ENDPOINT}/${viajeId}/estadias/`, data)
    .then((response) => {
      return response.data;
    });
};

const editViajeEstadiaTerminal = async (
  viajeId: number,
  estadiaId: number,
  data: EstadiaTerminalFormData
) => {
  return await axios
    .patch<EstadiaTerminal>(`${BASE_ENDPOINT}/${viajeId}/estadias/${estadiaId}`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteViajeEstadiaTerminal = async (viajeId: number, estadiaId: number) => {
  return await axios
    .delete<EstadiaTerminal>(`${BASE_ENDPOINT}/${viajeId}/estadias/${estadiaId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
            Tipos de operaciones
 ---------------------------------------- */

const getViajeTiposOperaciones = async (viajeId: number) => {
  return await axios
    .get<{ data: TipoOperacionViaje[] }>(`${BASE_ENDPOINT}/${viajeId}/operaciones/`)
    .then((response) => {
      return response.data.data;
    });
};

const getViajeTipoOperacion = async (viajeId: number, operacionId: number) => {
  return await axios
    .get<{ data: TipoOperacionViaje }>(`${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}`)
    .then((response) => {
      return response.data.data;
    });
};

const newViajeTiposOperaciones = async (viajeId: number, data: NewTipoOperacionViaje) => {
  return await axios
    .post<TipoOperacionViaje>(`${BASE_ENDPOINT}/${viajeId}/operaciones/`, data)
    .then((response) => {
      return response.data;
    });
};

const editViajeTiposOperaciones = async (
  viajeId: number,
  operacionId: number,
  data: NewTipoOperacionViaje
) => {
  return await axios
    .patch<TipoOperacionViaje>(`${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}`, data)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
      Operaciones no conteinerizadas
 ---------------------------------------- */

const newViajeOperacionNoConteinerizada = (
  viajeId: number,
  operacionId: number,
  data: NewContenidoNoConteinerizadoViaje
) => {
  return axios
    .post<ContenidoNoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-no-conteinerizado`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const getViajeOperacionesNoConteinerizada = (viajeId: number, operacionId: number) => {
  return axios
    .get<{ data: ContenidoNoConteinerizadoViaje[] }>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-no-conteinerizado`
    )
    .then((response) => {
      return response.data.data;
    });
};

const editViajeOperacionNoConteinerizada = (
  viajeId: number,
  operacionId: number,
  noConteinerizadoId: number,
  data: EditContenidoNoConteinerizadoViaje
) => {
  return axios
    .patch<ContenidoNoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-no-conteinerizado/${noConteinerizadoId}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const deleteViajeOperacionNoConteinerizada = (
  viajeId: number,
  operacionId: number,
  noConteinerizadoId: number
) => {
  return axios
    .delete<ContenidoNoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-no-conteinerizado/${noConteinerizadoId}`
    )
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
      Operaciones conteinerizadas
 ---------------------------------------- */

const newViajeOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  data: NewContenidoConteinerizadoViaje
) => {
  return axios
    .post<ContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const getViajeOperacionesConteinerizada = (viajeId: number, operacionId: number) => {
  return axios
    .get<{ data: ContenidoConteinerizadoViaje[] }>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado`
    )
    .then((response) => {
      return response.data.data;
    });
};

const getViajeOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  contenidoId: number
) => {
  return axios
    .get<{ data: ContenidoConteinerizadoViaje }>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${contenidoId}`
    )
    .then((response) => {
      return response.data.data;
    });
};

const editViajeOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  noConteinerizadoId: number,
  data: EditContenidoNoConteinerizadoViaje
) => {
  return axios
    .patch<ContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${noConteinerizadoId}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const deleteViajeOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  noConteinerizadoId: number
) => {
  return axios
    .delete<ContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${noConteinerizadoId}`
    )
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
  Productos de Operaciones conteinerizadas
 ---------------------------------------- */

const newViajeProductoOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  conteinerizadoId: number,
  data: NewProductoContenidoConteinerizadoViaje
) => {
  return axios
    .post<ProductoContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${conteinerizadoId}/productos`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const getViajeProductosOperacionesConteinerizada = (
  viajeId: number,
  operacionId: number,
  conteinerizadoId: number,
  params: PaginatedQueryParams
) => {
  return axios
    .get<{ data: ProductoContenidoConteinerizadoViaje[] }>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${conteinerizadoId}/productos`,
      { params }
    )
    .then((response) => {
      return response.data.data;
    });
};

const editViajeProductoOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  conteinerizadoId: number,
  productoId: number,
  data: EditProductoContenidoConteinerizadoViaje
) => {
  return axios
    .patch<ProductoContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${conteinerizadoId}/productos/${productoId}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const deleteViajeProductoOperacionConteinerizada = (
  viajeId: number,
  operacionId: number,
  conteinerizadoId: number,
  productoId: number
) => {
  return axios
    .delete<ProductoContenidoConteinerizadoViaje>(
      `${BASE_ENDPOINT}/${viajeId}/operaciones/${operacionId}/contenido-conteinerizado/${conteinerizadoId}/productos/${productoId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const ViajeService = {
  getViaje,
  getViajes,
  newViajeFacturado,
  getViajeFacturado,
  getViajeAuditorias,
  editViajeFacturado,
  deleteViajeFacturado,
  getViajeTipoOperacion,
  newViajeTiposOperaciones,
  getViajeTiposOperaciones,
  editViajeTiposOperaciones,
  newViajeEstadiaTerminal,
  getViajeEstadiaTerminal,
  editViajeEstadiaTerminal,
  deleteViajeEstadiaTerminal,
  getViajeEstadiasTerminales,
  getViajeOperacionConteinerizada,
  newViajeOperacionConteinerizada,
  editViajeOperacionConteinerizada,
  getViajeOperacionesConteinerizada,
  newViajeOperacionNoConteinerizada,
  deleteViajeOperacionConteinerizada,
  editViajeOperacionNoConteinerizada,
  getViajeOperacionesNoConteinerizada,
  deleteViajeOperacionNoConteinerizada,
  newViajeProductoOperacionConteinerizada,
  editViajeProductoOperacionConteinerizada,
  getViajeProductosOperacionesConteinerizada,
  deleteViajeProductoOperacionConteinerizada
};

export default ViajeService;
