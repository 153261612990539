import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TiposNavegacionService from '../../services/tiposNavegacion.service';
import { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposNavegacionService.getTiposNavegacion>>[number];

export const fetchTiposNavegacion = createAsyncThunk<Entity[]>(
  'tiposNavegacion/fetchTiposNavegacionStatus',
  (_params, api) => {
    const { tiposNavegacion } = api.getState() as RootState;
    if (tiposNavegacion.entities.length) return Promise.resolve(tiposNavegacion.entities);
    return TiposNavegacionService.getTiposNavegacion();
  }
);

interface TiposNavegacionState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposNavegacionState;

const tiposNavegacionSlice = createSlice({
  name: 'tiposBuque',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposNavegacion.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposNavegacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposNavegacion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposNavegacionActions = { ...tiposNavegacionSlice.actions, fetchTiposNavegacion };

export default tiposNavegacionSlice.reducer;
