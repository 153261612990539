import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createCompressor from 'redux-persist-transform-compress';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import mapReducer from './slices/map';
import authReducer from './slices/auth';
import paisesReducer from './slices/paises';
import puertosReducer from './slices/puertos';
import navierasReducer from './slices/navieras';
import productosReducer from './slices/productos';
import tiposBuqueReducer from './slices/tiposBuque';
import transportesReducer from './slices/transporte';
import loteDetalleReducer from './slices/loteDetalle';
import viajeDetalleReducer from './slices/viajeDetalle';
import contenedoresReducer from './slices/contenedores';
import puertoDetalleReducer from './slices/puertoDetalle';
import agenteMaritimoReducer from './slices/agenteMaritimo';
import tiposOperacionesReducer from './slices/tipoOperacion';
import tiposNavegacionReducer from './slices/tiposNavegacion';
import gastosIncluidosReducer from './slices/gastosIncluidos';
import pendientesCountReducer from './slices/pendientesCount';
import modosDeExplotacionReducer from './slices/modoExplotacion';
import intentoImportacionReducer from './slices/intentoImportacion';
import categoriasFacturacionReducer from './slices/categoriasFacturacion';
import modalidadesFletamientoReducer from './slices/modalidadFletamiento';
import subcategoriasFacturacionReducer from './slices/subcategoriaFacturacion';
import contenidoConteinerizadoDetalleReducer from './slices/contenidoConteinerizadoDetalle';

const appReducer = combineReducers({
  map: mapReducer,
  auth: authReducer,
  paises: paisesReducer,
  puertos: puertosReducer,
  navieras: navierasReducer,
  productos: productosReducer,
  tiposBuque: tiposBuqueReducer,
  transportes: transportesReducer,
  loteDetalle: loteDetalleReducer,
  contenedores: contenedoresReducer,
  viajeDetalle: viajeDetalleReducer,
  puertoDetalle: puertoDetalleReducer,
  agenteMaritimos: agenteMaritimoReducer,
  gastosIncluidos: gastosIncluidosReducer,
  pendientesCount: pendientesCountReducer,
  tiposNavegacion: tiposNavegacionReducer,
  tiposOperaciones: tiposOperacionesReducer,
  intentoImportacion: intentoImportacionReducer,
  modosDeExplotacion: modosDeExplotacionReducer,
  categoriasFacturacion: categoriasFacturacionReducer,
  modalidadesFletamiento: modalidadesFletamientoReducer,
  subcategoriasFacturacion: subcategoriasFacturacionReducer,
  contenidoConteinerizadoDetalle: contenidoConteinerizadoDetalleReducer
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'auth/removeUser') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// TODO: Reactivar cache
const whitelist = [
  'auth',
  'paises',
  'puertos',
  'navieras',
  'tiposBuque',
  'transportes',
  'gastosIncluidos',
  'agenteMaritimos',
  'tiposNavegacion',
  'tiposOperaciones',
  'modosDeExplotacion',
  'categoriasFacturacion',
  'modalidadesFletamiento',
  'subcategoriasFacturacion'
] as string[];

const compressor = createCompressor();

const persistedReducer = persistReducer(
  {
    storage,
    whitelist,
    key: 'root',
    transforms: [
      compressor,
      ...whitelist
        .filter((s) => s !== 'auth')
        .map((s) => expireReducer(s, { expireSeconds: 60 * 30 }))
    ]
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'map/setMapRef']
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
