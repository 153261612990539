import axios from '../config/axios';
import { API_URL } from '../config';

import type { AgenteMaritimo } from '../interfaces/agentesMaritimos';

/**
 * @description Recupera desde la API el listado de agentes marítimos vigentes
 */
const getAgentesMaritimos = async () => {
  return await axios
    .get<{ data: AgenteMaritimo[] }>(`${API_URL}/agente-maritimos`)
    .then((response) => {
      return response.data.data;
    });
};

const AgenteMaritimoService = {
  getAgentesMaritimos
};

export default AgenteMaritimoService;
