import axios from '../config/axios';
import { API_URL } from '../config';

import type {
  Lote,
  EstadoLote,
  LoteDetalle,
  LoteLogistica,
  LoteFacturado,
  LoteQueryParams,
  NewLoteFormData,
  EditLoteFormData,
  EstadoLoteQueryParams,
  LoteLogisticaQueryParams,
  NewLoteLogisticaFormData,
  EditLoteLogisticaFormData,
  NewLoteFacturacionFormData,
  EditLoteFacturacionFormData
} from '../interfaces/lote';
import type {
  Viaje,
  NewViajeFormData,
  EditViajeFormData,
  ViajestListQueryparams
} from '../interfaces/viajes';
import type { PaginatedResponse } from '../interfaces/api';
import type { Auditoria, AuditoriaQueryparams } from '../interfaces/auditoria';
import type { BuqueNovedad, BuquestListQueryparams, EditBuqueFormData } from '../interfaces/buques';

const BASE_URL = `${API_URL}/lotes`;

/* ---------------------------------------
                  LOTES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de lotes que puede ver el usuario
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Lote>`.
 */
const getLotes = async (params: LoteQueryParams = {}) => {
  return await axios.get<PaginatedResponse<Lote>>(BASE_URL, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API el lote solicitado
 * @param loteId id del lote a recuperar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 */
const getLote = async (loteId: number) => {
  return await axios.get<{ data: LoteDetalle }>(`${API_URL}/lotes/${loteId}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Registra en la api un nuevo lote
 * @param data datos del lote a crear
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para crear el lote.
 */
const newLote = async (data: NewLoteFormData) => {
  return await axios.post<LoteDetalle>(BASE_URL, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza en la api los datos de lote
 * @param loteId id del lote a editar
 * @param data datos del lote a editar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para editar el lote.
 */
const editLote = async (loteId: number, data: EditLoteFormData) => {
  return await axios.patch<LoteDetalle>(`${BASE_URL}/${loteId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina en la api el lote indicado (soft-delete)
 * @param loteId id del lote a eliminar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para eliminar el lote.
 * @throws si el lote no existe.
 * @throws si lote no está en estado borrador.
 */
const deleteLote = async (loteId: number) => {
  return await axios.delete<Lote>(`${BASE_URL}/${loteId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
          ESTADOS DE LOTES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de lotes que puede ver el usuario
 */
const getLotesEstados = async (params: EstadoLoteQueryParams) => {
  return await axios
    .get<{ data: EstadoLote[] }>(`${API_URL}/estados-lote`, { params })
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
              AUDITORIAS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de auditorías del lote indicado
 * @param loteId id del lote a recuperar las auditorias
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Auditoria>`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 */
const getLoteAuditorias = async (loteId: number, params: AuditoriaQueryparams) => {
  return await axios
    .get<PaginatedResponse<Auditoria>>(`${BASE_URL}/${loteId}/auditorias/`, { params })
    .then((response) => {
      return response.data.data;
    });
};

/* ---------------------------------------
              VIAJES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado paginado de viajes del lote indicado
 * @param loteId id del lote a recuperar los viajes
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Viaje>`.
 */
const getLoteViajes = async (loteId: number, params: ViajestListQueryparams = {}) => {
  return await axios
    .get<PaginatedResponse<Viaje>>(`${BASE_URL}/${loteId}/viajes/`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo viaje para el lote
 * @param loteId id del lote al que se registra el viaje
 * @param data datos del viaje a crear
 * @returns Un objeto de tipo `Viaje`.
 * @throws si el usuario no tiene permisos para crear el viaje.
 */
const newViaje = async (loteId: number, data: NewViajeFormData) => {
  return await axios.post<Viaje>(`${BASE_URL}/${loteId}/viajes/`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza en la api los datos de viaje para el lote
 * @param loteId id del lote al que pertenece el viaje
 * @param viajeId id del viaje a editar
 * @param data datos del viaje a editar
 * @returns Un objeto de tipo `Viaje`.
 * @throws si el usuario no tiene permisos para editar el viaje.
 * @throws si el viaje no existe.
 */
const editViaje = (loteId: number, viajeId: number, data: EditViajeFormData) => {
  return axios.patch<Viaje>(`${BASE_URL}/${loteId}/viajes/${viajeId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina en la api el viaje indicado (soft-delete)
 * @param loteId id del lote al que pertenece el viaje
 * @param viajeId id del viaje a eliminar
 * @returns Un objeto de tipo `Viaje`.
 * @throws si el usuario no tiene permisos para eliminar el viaje.
 * @throws si el viaje no existe.
 */
const deleteViaje = async (loteId: number, viajeId: number) => {
  return await axios.delete<Viaje>(`${BASE_URL}/${loteId}/viajes/${viajeId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
              FACTURACIÓN
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de facturación de lotes que puede ver el usuario
 * @param loteId id del lote a recuperar la facturación
 * @returns Un objeto de tipo `LoteFacturado[]`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 */
const getLotesFacturacion = async (loteId: number) => {
  return await axios
    .get<{ data: LoteFacturado[] }>(`${BASE_URL}/${loteId}/facturacion`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registrar en la api un nuevo registro de facturación para el lote
 * @param loteId id del lote al que se registra la información de facturación
 * @param data datos del detalle de facturación a crear
 * @returns Un objeto de tipo `LoteFacturado`.
 * @throws si el usuario no tiene permisos para crear la facturación.
 * @throws si el lote no existe.
 */
const newLoteFacturacion = async (loteId: number, data: NewLoteFacturacionFormData) => {
  return await axios
    .post<LoteFacturado>(`${BASE_URL}/${loteId}/facturacion`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos de facturación para el lote
 * @param loteId id del lote al que pertenece la facturación
 * @param facturadoId id del detalle de facturación a editar
 * @param data datos del detalle de facturación a editar
 * @returns Un objeto de tipo `LoteFacturado`.
 * @throws si el usuario no tiene permisos para editar la facturación.
 * @throws si el detalle de facturación no existe.
 * @throws si el lote no existe.
 */
const editLoteFacturacion = async (
  loteId: number,
  facturadoId: number,
  data: EditLoteFacturacionFormData
) => {
  return await axios
    .patch<LoteFacturado>(`${BASE_URL}/${loteId}/facturacion/${facturadoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el detalle de facturación indicado (soft-delete)
 * @param loteId id del lote al que pertenece la facturación
 * @param facturadoId id del detalle de facturación a eliminar
 * @returns Un objeto de tipo `LoteFacturado`.
 * @throws si el usuario no tiene permisos para eliminar la facturación.
 * @throws si el detalle de facturación no existe.
 * @throws si el lote no existe.
 */
const deleteLoteFacturacion = async (loteId: number, facturadoId: number) => {
  return await axios
    .delete<LoteFacturado>(`${BASE_URL}/${loteId}/facturacion/${facturadoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
              LOGISTICA
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de facturación de lotes que puede ver el usuario
 * @param loteId id del lote a recuperar la facturación
 * @returns Un objeto de tipo `LoteLogistica[]`.
 */
const getLotesLogisticas = async (loteId: number, params: LoteLogisticaQueryParams) => {
  return await axios
    .get<PaginatedResponse<LoteLogistica>>(`${BASE_URL}/${loteId}/logistica`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registrar en la api un nuevo registro de logística para el lote
 * @param loteId id del lote al que se registra la información de logística
 * @param data datos del detalle de logística a crear
 * @returns Un objeto de tipo `LoteLogistica`.
 * @throws si el usuario no tiene permisos para crear la logística.
 * @throws si el lote no existe.
 */
const newLoteLogistica = async (loteId: number, data: NewLoteLogisticaFormData) => {
  return await axios
    .post<LoteLogistica>(`${BASE_URL}/${loteId}/logistica`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos de logística para el lote
 * @param loteId id del lote al que pertenece la logística
 * @param logisticaId id del detalle de logística a editar
 * @param data datos del detalle de logística a editar
 * @returns Un objeto de tipo `LoteLogistica`.
 * @throws si el usuario no tiene permisos para editar la logística.
 * @throws si el detalle de logística no existe.
 * @throws si el lote no existe.
 */
const editLoteLogistica = async (
  loteId: number,
  logisticaId: number,
  data: EditLoteLogisticaFormData
) => {
  return await axios
    .patch<LoteLogistica>(`${BASE_URL}/${loteId}/logistica/${logisticaId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el detalle de logística indicado (soft-delete)
 * @param loteId id del lote al que pertenece la logística
 * @param logisticaId id del detalle de logística a eliminar
 * @returns Un objeto de tipo `LoteLogistica`.
 * @throws si el usuario no tiene permisos para eliminar la logística.
 * @throws si el detalle de logística no existe.
 * @throws si el lote no existe.
 */
const deleteLoteLogistica = async (loteId: number, logisticaId: number) => {
  return await axios
    .delete<LoteLogistica>(`${BASE_URL}/${loteId}/logistica/${logisticaId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
              BUQUES NOVEDADES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado paginado de novedades de buques que puede ver el usuario
 * @param loteId id del lote a recuperar las novedades
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<BuqueNovedad>`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 */
const getBuqueNovedades = async (loteId: number, params: BuquestListQueryparams = {}) => {
  return await axios
    .get<PaginatedResponse<BuqueNovedad>>(`${BASE_URL}/${loteId}/barcos-novedades/`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API la novedad de buque solicitada
 * @param loteId id del lote a recuperar la novedad
 * @param buqueNovedadId id de la novedad a recuperar
 * @returns Un objeto de tipo `BuqueNovedad`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 * @throws si la novedad no existe.
 */
const getBuqueNovedad = async (loteId: number, buqueNovedadId: number) => {
  return await axios
    .get<{ data: BuqueNovedad }>(`${BASE_URL}/${loteId}/barcos-novedades/${buqueNovedadId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API la novedad de buque solicitada
 * @param loteId id del lote a recuperar la novedad
 * @param buqueId id del buque a recuperar las novedades
 * @returns Un objeto de tipo `BuqueNovedad`.
 * @throws si el usuario no tiene permisos para ver el lote.
 * @throws si el lote no existe.
 * @throws si la novedad no existe.
 * @throws si el buque no existe.
 */
const getNovedadesParaBuque = async (loteId: number, buqueId: number) => {
  return await axios
    .get<PaginatedResponse<BuqueNovedad>>(`${BASE_URL}/${loteId}/barcos-novedades/`, {
      params: { barco_id: buqueId }
    })
    .then((response) => {
      return response.data.data[0];
    });
};

/**
 * @description Registra en la api una nueva novedad de buque para el lote
 * @param loteId id del lote al que se registra la información de la novedad
 * @param data datos de la novedad a crear
 * @returns Un objeto de tipo `BuqueNovedad`.
 * @throws si el usuario no tiene permisos para crear la novedad.
 * @throws si el lote no existe.
 * @throws si el buque no existe.
 * @throws si el buque ya tiene una novedad registrada.
 */
const newBuqueNovedad = async (loteId: number, data: EditBuqueFormData) => {
  return await axios
    .post<{ data: BuqueNovedad }>(`${BASE_URL}/${loteId}/barcos-novedades/`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la api los datos de la novedad de buque para el lote
 * @param loteId id del lote al que pertenece la novedad
 * @param buqueNovedadId id de la novedad a editar
 * @param data datos de la novedad a editar
 * @returns Un objeto de tipo `BuqueNovedad`.
 * @throws si el usuario no tiene permisos para editar la novedad.
 * @throws si el lote no existe.
 * @throws si la novedad no existe.
 */
const editBuqueNovedad = async (
  loteId: number,
  buqueNovedadId: number,
  data: EditBuqueFormData
) => {
  return await axios
    .patch<{ data: BuqueNovedad }>(`${BASE_URL}/${loteId}/barcos-novedades/${buqueNovedadId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la api la novedad de buque indicada (soft-delete)
 * @param loteId id del lote al que pertenece la novedad
 * @param buqueNovedadId id de la novedad a eliminar
 * @returns Un objeto de tipo `BuqueNovedad`.
 */
const deleteBuqueNovedad = async (loteId: number, buqueNovedadId: number) => {
  return await axios
    .delete<BuqueNovedad>(`${BASE_URL}/${loteId}/barcos-novedades/${buqueNovedadId}`)
    .then((response) => {
      return response.data;
    });
};

const LotesService = {
  newLote,
  getLote,
  getLotes,
  editLote,
  newViaje,
  editViaje,
  deleteLote,
  deleteViaje,
  getLoteViajes,
  getBuqueNovedad,
  newBuqueNovedad,
  getLotesEstados,
  editBuqueNovedad,
  newLoteLogistica,
  getBuqueNovedades,
  deleteBuqueNovedad,
  editLoteLogistica,
  getLoteAuditorias,
  getLotesLogisticas,
  newLoteFacturacion,
  deleteLoteLogistica,
  getLotesFacturacion,
  editLoteFacturacion,
  getNovedadesParaBuque,
  deleteLoteFacturacion
};

export default LotesService;
