import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

import TipoOperacionesService from '../../services/tiposOperaciones.service';

type Entity = Awaited<ReturnType<typeof TipoOperacionesService.getTiposOperaciones>>[number];

export const fetchTiposOperaciones = createAsyncThunk<Entity[]>(
  'tiposOperaciones/fetchTiposOperaciones',
  (_params, api) => {
    const { tiposOperaciones } = api.getState() as RootState;
    if (tiposOperaciones.entities.length) return Promise.resolve(tiposOperaciones.entities);
    return TipoOperacionesService.getTiposOperaciones();
  }
);

interface TiposOperacionesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposOperacionesState;

const tipoOperacionSlice = createSlice({
  name: 'tiposOperaciones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposOperaciones.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposOperaciones.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposOperaciones.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tipoOperacionActions = { ...tipoOperacionSlice.actions, fetchTiposOperaciones };

export default tipoOperacionSlice.reducer;
