import axios from '../config/axios';
import { API_URL } from '../config';

import type { ModoExplotacion } from '../interfaces/modoExplotacion';

/**
 * @description Recupera desde la API el listado de modos de explotación vigentes
 * @returns {Promise<ModoExplotacion[]>} Listado de modos de explotación
 */
const getModosDeExplotacion = async () => {
  return await axios
    .get<{ data: ModoExplotacion[] }>(`${API_URL}/modos-explotacion`)
    .then((response) => {
      return response.data.data;
    });
};

const ModoExplotacionService = {
  getModosDeExplotacion
};

export default ModoExplotacionService;
