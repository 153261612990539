import axios from '../config/axios';
import { API_URL } from '../config';

import type { Transporte } from '../interfaces/transportes';

/**
 * @description Recupera desde la API el listado de tipos de transportes
 * @returns {Promise<Transporte[]>}
 */
const getTiposTransporte = async () => {
  return await axios.get<{ data: Transporte[] }>(`${API_URL}/transporte`).then((response) => {
    return response.data.data;
  });
};

const TransporteService = {
  getTiposTransporte
};

export default TransporteService;
