import React, { ReactNode } from 'react';

import Footer from './components/Footer';
import ProLayout from '@ant-design/pro-layout';
import { Link, useLocation } from 'react-router-dom';
import RightContent from './components/RightContent';

import logo from '../../assets/images/appLogo.png';
import { useAppSelector } from '../../store/store';
import { PRIMARY_COLOR } from '../../constants/colors';
import { useWindowSize } from '../../utils/useWindowSize';

import getMenuItems from './getMenuItems';

import type { IUser } from '../../interfaces/user';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { width } = useWindowSize();

  const location = useLocation();
  const isMobile = width && width <= 767;

  const user = useAppSelector((state) => state.auth.user) as IUser;

  const routes = getMenuItems(isMobile as boolean, user);

  return (
    <ProLayout
      // layout="mix"
      logo={logo}
      fixSiderbar
      location={location}
      route={routes}
      // onCollapse={setIsCollapsed}
      style={{ height: '100vh' }}
      footerRender={() => <Footer />}
      collapsed={!isMobile ? true : undefined}
      menuFooterRender={() => <RightContent />}
      collapsedButtonRender={!isMobile ? () => null : undefined}
      title="Plataforma informática operatoria portuaria bonaerense"
      menuItemRender={(data, dom) => {
        // @ts-ignore
        return data.href ? (
          <a target="_blank" href={data.href} rel="noreferrer">
            {dom}
          </a>
        ) : data.path ? (
          <Link target={data.target} to={data.path}>
            {dom}
          </Link>
        ) : (
          dom
        );
      }}
      token={{
        header: { colorBgHeader: PRIMARY_COLOR, colorTextMenu: 'white' },
        sider: {
          colorMenuBackground: PRIMARY_COLOR,
          // colorTextMenu: isMobile ? undefined : 'white',
          colorTextMenuActive: isMobile ? undefined : '#dedede',
          // colorBgMenuItemHover: isMobile ? undefined : '#dedede',
          colorTextMenuSelected: isMobile ? undefined : '#dedede',
          // colorTextMenuItemHover: isMobile ? undefined : '#dedede',
          colorTextMenuSecondary: isMobile ? undefined : '#dedede',
          colorTextSubMenuSelected: isMobile ? undefined : '#dedede',
          colorBgMenuItemCollapsedSelected: isMobile ? undefined : '#dedede'
        }
      }}
    >
      {props.children}
    </ProLayout>
  );
};

export default Layout;
