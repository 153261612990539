import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Map } from 'leaflet';

const initialState: { mapRef: Map | null } = { mapRef: null };

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapRef(state, action: PayloadAction<{ mapRef: Map | null }>) {
      state.mapRef = action.payload.mapRef;
    }
  }
});

export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
