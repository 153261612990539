import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import CategoriasFacturacionService from '../../services/categoriasFacturacion.service';

type Entity = Awaited<
  ReturnType<typeof CategoriasFacturacionService.getCategoriasFacturacion>
>[number];

export const fetchCategoriasFacturacion = createAsyncThunk<Entity[]>(
  'categoriasFacturacion/fetchCategoriasFacturacionStatus',
  (_params, api) => {
    const { categoriasFacturacion } = api.getState() as RootState;
    if (categoriasFacturacion.entities.length)
      return Promise.resolve(categoriasFacturacion.entities);
    return CategoriasFacturacionService.getCategoriasFacturacion();
  }
);

interface CategoriasFacturacionState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as CategoriasFacturacionState;

const categoriasFacturacionSlice = createSlice({
  name: 'categoriasFacturacion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriasFacturacion.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchCategoriasFacturacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchCategoriasFacturacion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const categoriasFacturacionActions = {
  ...categoriasFacturacionSlice.actions,
  fetchCategoriasFacturacion
};

export default categoriasFacturacionSlice.reducer;
